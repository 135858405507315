<template>
  <div class="container">
    <div class="static-page">
      <div class="contact">
        <img src="@/assets/images/static-page/contact/contact-image.png">
        <h3 class="title-page">Контакты</h3>
        <div class="contact-block">
          <div class="left">
            <p>Если у вас возник вопрос по бронированию, то, пожалуйста, свяжитесь с нами по телефону или с помощью
              формы обратной связи. Служба поддержки работает онлайн в режиме <b>24/7</b> без выходных и праздников.</p>
            <div class="phones">
              <div class="left">
                <label class="phone-label"><b>Россия:</b></label>
                <div class="phone">8 (800) 777-59-26</div>
                <div class="phone-help">(звонок бесплатный)</div>
              </div>
              <div class="right">
                <label class="phone-label"><b>Москва:</b></label>
                <div class="phone">8 (495) 225-81-11</div>
              </div>
            </div>
            <p>Мы находимся в самом центре Москвы, в 10 минутах от метро “Ленинский проспект”, рядом с офисом есть
              платная
              парковка для автомобилей и других транспортных средств.</p>
            <h3 class="contact-title"><b>Информация о компании:</b></h3>
            <p>Полное наименование: Общество с ограниченной ответственностью «Аламат»<br>Сокращенное наименование: ООО
              «Аламат» <br>ИНН: 9725081236 <br>КПП: 772501001 <br>ОГРН: 1227700220989 <br>ОКПО: 73594160 <br>ОКАТО:
              45296561000 <br>ОКТМО: 45915000000
              <br>ОКОГУ: 4210014
              <br>ОКФС: 16
              <br>ОКОПФ: 12300
              <br>Юридический адрес: г. Москва, 2-й Рощинский проезд, д. 8, офис 918
              <br>Фактический адрес: г. Москва, 2-й Рощинский проезд, д. 8, офис 918
              <br>Email: info@alamat.ru
              <br>Телефон: 8(926) 390 - 09 - 09</p>
            <h3 class="contact-title">Банковские реквизиты:</h3>
            <p>Банк: АО “МИнБанк”
              <br>р/с: 40702810000990001199,
              <br>БИК 044525600, <br>кор. счёт 30101810300000000600</p>
          </div>
          <div class="right coordinate">
            <img src="@/assets/images/static-page/contact/contact-map.png">
            <span>Офис в Москве</span>
            <p>ООО “Аламат” - Туроператор и агрегатор по бронированию
              г. Москва, 2-о1 Рощинский проезд, д8, офис 918</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  async mounted() {

  },
  methods: {}
}
</script>
